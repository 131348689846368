
*::-webkit-scrollbar {
    scrollbar-width: thin;
    width: 5px;
    height: 5px;
}

*::-webkit-scrollbar-track {
    background: #f1eded; /* Light grey color */
    border-radius: 14px;
}

*::-webkit-scrollbar-thumb {
    background-color: #b5b4b4; /* Specific color for thumb */
    border-radius: 14px;
}

*::-webkit-scrollbar-horizontal {
    scrollbar-width: thin;
    width: 5px;
}